/* Base styles */
:root {
  --primary-color: #2563eb;
  --secondary-color: #1e40af;
  --background-light: #f8fafc;
  --text-dark: #1a202c;
  --text-light: #4a5568;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.6;
  color: var(--text-dark);
}

/* Navigation */
.nav {
  background-color: var(--text-dark);
  color: white;
  padding: 1rem;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.nav-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-brand {
  font-size: 1.25rem;
  font-weight: bold;
}

.nav-links a {
  color: white;
  text-decoration: none;
  margin-left: 2rem;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: var(--primary-color);
}

/* Hero Section */
.hero {
  background-color: var(--background-light);
  text-align: center;
  padding: 6rem 2rem;
  border-bottom: 1px solid #e2e8f0;
}

.hero h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: var(--text-dark);
}

.hero-subtitle {
  font-size: 1.5rem;
  color: var(--text-light);
  margin-bottom: 2rem;
}

.certifications {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;
}

.cert-badge {
  background-color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 9999px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  font-weight: 500;
  color: var(--primary-color);
  text-decoration: none;
  transition: transform 0.2s, box-shadow 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  position: relative;
}

.cert-badge:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  background-color: var(--primary-color);
  color: white;
}


/* Sections */
.section {
  padding: 5rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.section h2 {
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: center;
}

/* About Section */
.about-content {
  max-width: 800px;
  margin: 0 auto;
}

.lead-text {
  font-size: 1.25rem;
  color: var(--text-light);
  text-align: center;
  margin-bottom: 3rem;
}

.experience-highlights {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.highlight-card {
  text-align: center;
  margin-bottom: 1rem;
  padding: 1.5rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.highlight-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

/* Services Section */
.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.service-card {
  text-align: left;
  margin-bottom: 1rem;
  border: 1px solid #e2e8f0;
  padding: 2rem;
  border-radius: 0.5rem;
  background-color: white;
  transition: transform 0.2s, box-shadow 0.2s;
}

.service-card h3 {
  text-align: center;
  margin-top: 0rem;
  margin-bottom: 1rem;
  padding: 2rem;
  border-radius: 0.5rem;
  background-color: white;
  transition: transform 0.2s, box-shadow 0.2s;
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.service-list {
  list-style-type: none;
  padding: 0;
  margin: 1rem 0 0 0;
}

.service-list li {
  padding: 0.5rem 0;
  padding-left: 1.5rem;
  position: relative;
}

.service-list li::before {
  content: "•";
  color: var(--primary-color);
  position: absolute;
  left: 0;
}

/* Expertise Section */
.expertise-section {
  background-color: var(--background-light);
}

.expertise-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.expertise-intro {
  color: var(--text-light);
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.expertise-card {
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.expertise-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.expertise-list li {
  margin-bottom: 1.25rem;
  line-height: 1.5;
}

.expertise-card h3 {
  text-align: center;
  background-color: white;
  margin-bottom: 1rem;
  color: var(--text-dark);
}

.skill-list {
  margin-top: 1.5rem;
}

.skill-item {
  margin-bottom: 1rem;
}

.skill-name {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.skill-bar {
  height: 8px;
  background-color: #e2e8f0;
  border-radius: 4px;
  overflow: hidden;
}

.skill-level {
  height: 100%;
  background-color: var(--primary-color);
  border-radius: 4px;
}

.expertise-list {
  list-style: none;
  padding: 0;
  margin: 1.5rem 0 0 0;
}

.expertise-list li {
  padding: 0.5rem 0;
  position: relative;
  padding-left: 1.5rem;
}

.expertise-list li::before {
  content: "•";
  color: var(--primary-color);
  position: absolute;
  left: 0;
  font-weight: bold;
}

/* Contact Section */
.contact-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  max-width: 1000px;
  margin: 0 auto;
}

.contact-info {
  text-align: center;
  padding: 2rem;
  background-color: var(--background-light);
  border-radius: 0.5rem;
}

.contact-info p {
  margin: 1.5rem auto;
  max-width: 800px;
}

.contact-highlights {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .5rem;
}

.contact-highlight {
  margin: 0.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.contact-highlight strong {
  margin-bottom: 0.25rem;
  display:block
}
.contact-form {
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  transition: border-color 0.2s;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
}

.button {
  background-color: var(--primary-color);
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
  width: 100%;
}

.button:hover {
  background-color: var(--secondary-color);
}

/* Footer */
.footer {
  background-color: var(--text-dark);
  color: white;
  padding: 2rem;
  margin-top: 4rem;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-links a {
  color: white;
  text-decoration: none;
  margin-left: 2rem;
}

.footer-links a:hover {
  color: var(--primary-color);
}

/* Responsive Design */
@media (max-width: 768px) {
  .nav-content {
    flex-direction: column;
    text-align: center;
  }
  
  .nav-links {
    margin-top: 1rem;
  }
  
  .nav-links a {
    margin: 0 1rem;
  }

  .hero h1 {
    font-size: 2rem;
  }

  .hero-subtitle {
    font-size: 1.25rem;
  }

  .certifications {
    flex-direction: column;
    gap: 1rem;
  }

  .contact-container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}

@media (max-width: 480px) {
  .section {
    padding: 3rem 1rem;
  }

  .service-card,
  .expertise-card {
    padding: 1.5rem;
  }

  .footer-content {
    flex-direction: column;
    text-align: center;
    gap: 1rem;
  }

  .footer-links a {
    margin: 0 0.5rem;
  }
}